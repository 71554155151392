@import "styles/reset";

@import url('https://fonts.googleapis.com/css?family=Abril+Fatface&display=swap');

html {
  height: 100vh;
  width: 100vw;
}

body {
  background: #dec7e6;
  height: 100%;
}

#root {
  height: 100%;
}

.bgio-client {
  display: flex;
  height: 100%;
  justify-content: center;
}

.triple-triad {
  min-width: 850px;
}

.game-ui-wrapper {
  width: 100%;
  height: 80px;
}

.game-ui {
  max-width: 850px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.turn-UI {
  max-width: 850px;
  margin: 0 auto;
  position: relative;
  width: 100%;
  height: 50px;
  &.p1-turn .turn-UI--svg-wrapper {
    left: 0;
  }
  &.p2-turn .turn-UI--svg-wrapper {
    right: 0;
  }
}
.turn-UI--svg-wrapper {
  position: absolute;
  z-index: 3;
  top: 0;
  width: 170px;
  text-align: center;
  transition: all 222ms linear;
}
.turn-UI--svg-wrapper svg {
  animation-name: spinner;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 1s;
}

@keyframes spinner {
   from {
     transform: rotate3d(0, 1, 0, 0deg);
   }
   to {
     transform: rotate3d(0, 1, 0, 360deg);
   }
 }


.topbar-title {
  flex: 1;
  text-align: center;
}
.topbar-title h1 {
  font-family: 'Abril Fatface', cursive;
  font-weight: 400;
  font-size: 40px;
  margin-top: 10px;
  margin-bottom: 0;

}

.player-header {
  width: calc(140px + 1em);
  text-align: center;
}
.player2-header {
  transform: translateX(-1em);
}
.player-name p {
  font-weight: bold;
  margin: 5px 0;
}
.player-score h2 {
  font-weight: 600;
  margin: 5px 0;
}

.game-board {
  align-items: flex-start;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-height: 90vh;
  max-width: 850px;
}

.board {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  height: 600px;
  margin-top: 75px;
  border: 2px solid #aaa;
  box-shadow: 0px 0px 15px rgba(0,0,0,0.2);
}

.cell:nth-child(even) {
  background: #efefde;
}

.cell:nth-child(odd) {
  background: #dedecd;
}

.cell {
  align-items: center;
  color: #fff;
  display: flex;
  justify-content: center;
  height: 200px;
  min-width: 170px;
}

.card {
  border-radius: 5px;
  display: grid;
  margin: 1em;
  padding: .25em;
  height: 180px;
  max-height: 180px;
  width: 130px;
  max-width: 130px;
  background-size: cover !important;
  &.blank {
    display: none;
  }
}

.card-image-wrapper {
  background-size: cover;
  position: relative;
}

.player {
  max-height: 90vh;
  min-width: 172px;
  pointer-events: none;
  &.current {
    pointer-events: auto;
  }
}

.card.player1 {
  background: #80252b;
}
.player1 .card-image-wrapper:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255,0,0,0.1);
  z-index: 1;
}

.card.player2 {
  background: #275476;
}
.player2 .card-image-wrapper:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,255,0.1);
  z-index: 1;
}
.player .card {
  box-shadow: 0px -6px 12px rgba(0,0,0,0.5);
  // box-shadow: var(--shadow-elevation-high);
}
.card.active {
  border: 4px solid #FFF;
  box-shadow: 2px -5px 12px rgba(255,255,153, 0.8);
}
.player .draggable-card-wrapper:nth-of-type(1) {
  transform: translateY(0);
}
.player .draggable-card-wrapper:nth-of-type(2) {
  transform: translateY(-75px);
}
.player .draggable-card-wrapper:nth-of-type(3) {
  transform: translateY(-150px);
}
.player .draggable-card-wrapper:nth-of-type(4) {
  transform: translateY(-225px);
}
.player .draggable-card-wrapper:nth-of-type(5) {
  transform: translateY(-300px);
}

.card-scores {
  display: grid;
  grid-template-areas: ". top ."
                       "left . right"
                       ". bottom .";
  height: 50px;
  width: 40px;
  z-index: 2;
  position: relative;
}
.card-score {
  color: white;
  font-family: "Roboto", sans-serif;
  font-size: 25px;
  font-weight: bold;
  text-shadow: 0px 0px 3px #000;
  margin: 0;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}
.card-score:nth-child(1) {
  grid-area: top;
}
.card-score:nth-child(2) {
  grid-area: right;
}
.card-score:nth-child(3) {
  grid-area: bottom;
}
.card-score:nth-child(4) {
  grid-area: left;
}

.endgame-modal--wrapper, .modal-wrapper {
  position: fixed;
  z-index:5;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0,0,0,0.4);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.endgame-modal {
  background-color: #fff;
  border-radius: 5px;
  min-width: 460px;
  padding: 20px;
  h1 {
    font-family: 'Abril Fatface', cursive;
    font-weight: 400;
  }
}

h2 {
  font-family: 'Abril Fatface', cursive;
}

ol {
  font-family: sans-serif;
  li {
    padding-bottom: 15px;
  }
}

.tutorial-image {
  display: flex;
  justify-content: center;
  margin: 1em;
  margin-bottom: 0.5em;
}
// small device

@media (max-width: 768px) {
  .triple-triad {
    min-width: auto;
  }
  .game-board {
    display: grid;
    flex-direction: column;
    max-height: 100%;
    max-width: 100vw;
  }
  .player {
    align-items: center;
    display: flex;
    .draggable-card-wrapper:nth-of-type(1) {
      transform: translate(0, 0);
     }
    .draggable-card-wrapper:nth-of-type(2) {
      transform: translate(-95px, 0);
    }
    .draggable-card-wrapper:nth-of-type(3) {
      transform: translate(-95px * 2, 0);
    }
      .draggable-card-wrapper:nth-of-type(4) {
      transform: translate(-95px * 3 , 0);
    }
    .draggable-card-wrapper:nth-of-type(5) {
      transform: translate(-95px * 4, 0);
    }
  }
}